import {
    SET_SALES_SUMMARY_TABLE_PARAMS,
    SET_COLLECTIONS_SUMMARY_TABLE_PARAMS,
    SET_ADVANCE_COLLECTIONS_TABLE_PARAMS,
    SET_SETTLEMENTS_TABLE_PARAMS,
    SET_QUOTATIONS_SUMMARY_TABLE_PARAMS,
    SET_LOAD_SUMMARY_TABLE_PARAMS,
    SET_UNLOAD_SUMMARY_TABLE_PARAMS,
    SET_INVENTORY_TABLE_PARAMS,
    SET_USERS_TABLE_PARAMS,
    SET_EXPENSE_SUMMARY_TABLE_PARAMS,
    SET_SALESMAN_DAMAGE_SUMMARY_TABLE_PARAMS,
    SET_DAY_TRACK_LOG_TABLE_PARAMS,
    SET_CREDIT_DEBIT_NOTE_TABLE_PARAMS,
    SET_ROUTE_JOURNEY_LOG_TABLE_PARAMS,
    SET_PENDING_QUOTATIONS_SUMMARY_TABLE_PARAMS,
} from '../actions/types';

const initialState = {
    salesSummary: {},
    collectionsSummary: {},
    quotationsSummary: {},
    pendingQuotationsSummary: {},
    advanceCollections: {},
    settlements: {},
    loadSummary: {},
    unloadSummary: {},
    inventoryTable: {},
    users: {},
    expenseSummary: {},
    salesmanDamageSummary: {},
    dayTrackLog: {},
    creditDebitNote: {},
    routeJourneyLog: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SALES_SUMMARY_TABLE_PARAMS:
            return {
                ...state,
                salesSummary: action.payload.params,
            };
        case SET_COLLECTIONS_SUMMARY_TABLE_PARAMS:
            return {
                ...state,
                collectionsSummary: action.payload.params,
            };
        case SET_QUOTATIONS_SUMMARY_TABLE_PARAMS:
            return {
                ...state,
                quotationsSummary: action.payload.params,
            };
        case SET_PENDING_QUOTATIONS_SUMMARY_TABLE_PARAMS:
            return {
                ...state,
                pendingQuotationsSummary: action.payload.params,
            };
        case SET_LOAD_SUMMARY_TABLE_PARAMS:
            return {
                ...state,
                loadSummary: action.payload.params,
            };
        case SET_UNLOAD_SUMMARY_TABLE_PARAMS:
            return {
                ...state,
                unloadSummary: action.payload.params,
            };
        case SET_ADVANCE_COLLECTIONS_TABLE_PARAMS:
            return {
                ...state,
                advanceCollections: action.payload.params,
            };
        case SET_SETTLEMENTS_TABLE_PARAMS:
            return {
                ...state,
                settlements: action.payload.params,
            };
        case SET_INVENTORY_TABLE_PARAMS:
            return {
                ...state,
                inventoryTable: action.payload.params,
            };
        case SET_USERS_TABLE_PARAMS:
            return {
                ...state,
                users: action.payload.params,
            };
        case SET_EXPENSE_SUMMARY_TABLE_PARAMS:
            return {
                ...state,
                expenseSummary: action.payload.params,
            };
        case SET_SALESMAN_DAMAGE_SUMMARY_TABLE_PARAMS:
            return {
                ...state,
                salesmanDamageSummary: action.payload.params,
            };
        case SET_DAY_TRACK_LOG_TABLE_PARAMS:
            return {
                ...state,
                dayTrackLog: action.payload.params,
            };
        case SET_CREDIT_DEBIT_NOTE_TABLE_PARAMS:
            return {
                ...state,
                creditDebitNote: action.payload.params,
            };
        case SET_ROUTE_JOURNEY_LOG_TABLE_PARAMS:
            return {
                ...state,
                routeJourneyLog: action.payload.params,
            };
        default:
            return state;
    }
};

export default reducer;
