import React, {useEffect, useState} from 'react';
import {PDFDownloadLink,PDFViewer} from '@react-pdf/renderer';
import {useHistory} from 'react-router-dom';
import InvoicePDF from './QuotationPrint';
import {Modal, Button} from 'antd';

function InvoiceView({Invoice}) {
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [isDownloaded, setIsDownloaded] = useState(false);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const history = useHistory();

    useEffect(() => {

        if (Invoice) {
            setShowDownloadButton(true);
            setModalIsVisible(true);
        }
    }, [Invoice]);

    useEffect(() => {
        if (isDownloaded) {
            setModalIsVisible(false);
            history.push('/quotations-summary');
        }
    }, [isDownloaded, history]);

    const handleDownloadClick = () => {
        setIsDownloaded(true);
    };

    const handleCancel = () => {
        setModalIsVisible(false);
    };

    return (
        <div>
            <Modal
                title='Invoice Preview'
                open={modalIsVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="download" type="primary" onClick={handleDownloadClick}>
                        <PDFDownloadLink
                            document={<InvoicePDF InvoiceData={Invoice}  />}
                            fileName='Quotation.pdf'
                            style={{
                                textDecoration: 'none',
                                color: '#fff',
                            }}
                        >
                            {({ loading }) => (loading ? 'Preparing document...' : 'Download Invoice')}
                        </PDFDownloadLink>
                    </Button>
                ]}
                width={600}

            >
                <PDFViewer style={{ width: '100%', height: '600px' }}>
                    <InvoicePDF InvoiceData={Invoice}  />
                </PDFViewer>
            </Modal>
        </div>
    );
}

export default InvoiceView;
