import React, {useState, useEffect, useContext, useRef} from 'react';

import {
    Input,
    Form,
    Button,
    Table,
    Row,
    message,
    Descriptions,
    Typography,
    Select,
    DatePicker,
    InputNumber,
    Modal,
    Tooltip,
    Col,
} from 'antd';

import {LeftOutlined} from '@ant-design/icons';

import moment from 'moment/moment';
import {useLocation, useHistory} from 'react-router-dom';

import {
    getApiWithAuthToken,
    postApiWithAuthToken,
    updateApiWithAuthToken,
} from 'api';
import InvoiceView from './QuotationView';
import {convertLocalToUTC} from 'helpers/utcTime';

const {Text} = Typography;
const {TextArea} = Input;

const AddQuotations = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const quote_code = new URLSearchParams(useLocation().search).get(
        'quote_code',
    );

    //Table States

    const [route, setRoute] = useState();
    const [customer, setCustomer] = useState({});
    const [transactionCode, setTransactionCode] = useState('');

    const [routeCode, setRouteCode] = useState();
    const [routeName, setRouteName] = useState('');
    const [routeDet, setRouteDet] = useState([]);
    const [salesmanId, setSalesmanId] = useState();
    const [salesmanName, setSalesmanName] = useState('');
    const [salesmanCode, setSalesmanCode] = useState('');

    const [vatamt, setVatAmt] = useState();

    const [vatRegNo, setVatRegNo] = useState();

    const [currency, setCurrency] = useState();
    const [currencyDet, setCurrencyDet] = useState([]);

    const [roundingRule, setRoundingRule] = useState('');
    const [roundingPrecision, setRoundingPrecision] = useState(0);
    const [currencyId, setCurrencyId] = useState();
    const [currencyCode, setCurrencyCode] = useState();

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [loading, setLoading] = useState(true);
    const [vatSum, setVatSum] = useState();
    const [editStart, setEditStart] = useState();
    const [editDuration, setEditDuration] = useState(0);
    const [duration, setDuration] = useState(0);
    const [customerDet, setCustomerDet] = useState([]);

    const [expSalesDate, setExpSalesDate] = useState(null);
    const [confirmFinal, setConfirmFinal] = useState(false);
    const [invoiceData, setInvoiceData] = useState({});
    const [remarks, setRemarks] = useState('');
    const [quotationSummary, setQuotationsSummary] = useState({});

    const [confirm, setConfirm] = useState(false);
    const [custData, setCustData] = useState(true);

    const [productId, setSelectProductId] = useState();
    const [editable, setEditable] = useState(false);

    //get quotationSummary

    function getQuotationsSummary() {
        getApiWithAuthToken(
            `/api/v1/admin/quotationsSummary?invoice_no=${quote_code}`,
        ).then(res => {
            if (res.data && res.data.quotations.length > 0) {
                setQuotationsSummary(res.data.quotations[0]);
                setVatSum(res.data.quotations[0].vat_perc);
                setEditStart(moment().format('HH:mm:ss'));
                setExpSalesDate(res.data.quotations[0].exp_sale_date);
            }
        });
    }

    //get quotation Detail page

    const getQuotationsDetailsList = () => {
        getApiWithAuthToken(
            `/api/v1/admin/quotationsDetails?quote_code=${quote_code}`,
        ).then(res => {
            if (!res.error && res.data.quotedetails.length) {
                const formattedData = res.data.quotedetails.map(each => ({
                    key: each.id,
                    product: {
                        id: each.product,
                        name: each.product_name,
                        code: each.product_code,
                    },
                    multipack: {
                        id: each.multipack_id,
                        name: each.multipack_name,
                        code: each.multipack_code,
                        multiplier: each.multiplier,
                    },
                    base_multipack: {
                        id: each.base_multipack_id,
                        code: each.base_multipack_code,
                        name: each.base_multipack_name,
                    },
                    group_id: each.group_id,
                    group_code: each.group_code,
                    group_name: each.group_name,

                    requested_quantity: each.requested_quantity,
                    assigned_quantity: each.assigned_quantity,
                    salePrice: each.sale_price,
                    discount:
                        each.discount_amount !== '' ? each.discount_amount : 0,
                    max_discount: each.max_discount,
                    tax_1: each.tax_1,
                    tax_2: each.tax_2,
                    tax_perc: each.tax_1 + each.tax_2,

                    total: each.net_amount,
                    cost_price: each.cost_price,
                    price_id: each.price_id,
                    min_sales: each.min_sales,
                    detail_code: each.detail_code,
                    quote_code: each.quote_code,
                    product_price: each.product_price,

                    fromApi: true,
                }));
                setDataSource(formattedData);
            } else {
                if (res.errors) {
                    Object.values(res.errors).forEach(each =>
                        message.error(each.msg),
                    );
                } else {
                }
            }
            setLoading(false);
        });
    };
    useEffect(() => {
        getQuotationsSummary();
        getQuotationsDetailsList();
    }, []);
    useEffect(() => {
        // Generate the transaction code
        setTransactionCode(Date.now().toString());
    }, []);

    //

    //get vat and vat registration details
    useEffect(() => {
        if (customer) {
            try {
                getApiWithAuthToken(`/api/v1/admin/customers/${customer.id}`)
                    .then(res => {
                        if (!res.error && res.data) {
                            const {rounding_rule, rounding_precision} =
                                res.data;
                            setRoundingRule(rounding_rule);
                            setRoundingPrecision(rounding_precision);
                            setVatAmt(res.data.vat);

                            setCurrency(res.data.def_currency);
                            setCurrencyId(res.data.def_currency_id);
                            setCurrencyCode(res.data.currency_code);
                        }
                    })
                    .catch(error => {});
            } catch (error) {}
        }
    }, [customer]);

    //get company Details

    function getCompanyDetails() {
        getApiWithAuthToken('/api/v1/admin/company').then(res => {
            if (res.data) {
                setVatRegNo(res?.data?.vat_reg_no);
                // Assuming multi_sales_type_in_single is part of the response
            } else {
                setVatRegNo('');
            }
        });
    }

    // final confirm
    const FinalSubmit = async () => {
        try {
            //invalid assigned quantity
            const invalidProduct = dataSource.find(
                item => item.assigned_quantity < 1,
            );

            if (invalidProduct) {
                const productName =
                    invalidProduct.product.name ||
                    invalidProduct.product_code ||
                    `Product at row ${invalidProduct.key}`;
                message.error({
                    content: `The Assigned quantity for product "${productName}" must be at least 1.`,
                    key: 'form',
                });
                return; // Prevent submission if validation fails
            }
            // Collect values from the first set of form items
            const formData = form.getFieldsValue();

            // Convert the dataSource array to the desired format
            const formattedDataSource = dataSource.map(item => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.assigned_quantity || 0);
                const vatPercentage = parseFloat(
                    item.tax_perc > 0 ? item.tax_perc : 0 || 0,
                );
                const discount = parseFloat(item.discount || 0);

                const grossAmount = salePrice * quantity;
                const totalTaxableAmount = grossAmount - discount;
                const vatAmount = totalTaxableAmount * vatPercentage;
                const netAmount = totalTaxableAmount + vatAmount;
                const discountPercentage = discount / grossAmount;

                return {
                    ...item,
                    product: item.product.id, // Replace the product object with its ID
                    product_name: item.product.name,
                    product_code: item.product.code,
                    multipack_id: item.multipack.id,

                    multipack_name: item.multipack.name,
                    base_multipack_name: item.multipack.base_multipack_name,
                    base_multipack_id: item.multipack.base_multipack_id,
                    base_multipack_code: item.multipack.base_multipack_code,
                    multiplier: item.multipack.multiplier,
                    tax_total: roundNumber(
                        vatAmount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    gross_amount: roundNumber(
                        grossAmount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    net_amount: roundNumber(
                        netAmount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    total_taxable_amount: roundNumber(
                        totalTaxableAmount,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                    discount_perc: roundNumber(
                        discountPercentage,
                        roundingRule,
                        roundingPrecision + 1,
                    ),
                };
            });

            // Calculate the total sales, total discount, total VAT, and total net amount
            const totalSales = dataSource.reduce((total, item) => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.assigned_quantity || 0);
                const gross = salePrice * quantity;
                return total + gross;
            }, 0);
            const totalGrossAmount = dataSource.reduce((total, item) => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.assigned_quantity || 0);
                const gross = salePrice * quantity;
                return total + gross;
            }, 0);

            const totalVat = dataSource.reduce((total, item) => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.assigned_quantity || 0);
                const tax = parseFloat(item.tax_perc || 0);
                const vat = salePrice * quantity * tax;
                return total + vat;
            }, 0);

            const totalDiscount = dataSource.reduce((total, item) => {
                const discount = parseFloat(item.discount || 0);
                return total + discount;
            }, 0);

            const totalNetAmount = dataSource.reduce((total, item) => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.assigned_quantity || 0);
                const vat = parseFloat(item.tax_perc || 0);
                const discount = parseFloat(item.discount || 0);
                const gross = salePrice * quantity;
                const taxable = gross - discount;
                const vatAmount = taxable * vat;
                const netAmount = taxable + vatAmount;
                return (
                    total +
                    roundNumber(netAmount, roundingRule, roundingPrecision + 1)
                );
            }, 0);
            const totalTaxableAmount = dataSource.reduce((total, item) => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.assigned_quantity || 0);
                const discount = parseFloat(item.discount || 0);
                const gross = salePrice * quantity;
                const netAmount = gross - discount;
                return total + netAmount;
            }, 0);

            const formattedDate = moment(formData.date).format('YYYY-MM-DD');
            // Get the current date and time
            const currentTime = moment().format('HH:mm:ss');
            const endTime = moment().format('HH:mm:ss');
            const duration = moment
                .duration(
                    moment(endTime, 'HH:mm:ss').diff(
                        moment(startTime, 'HH:mm:ss'),
                    ),
                )
                .asSeconds();

            setDuration(duration);

            // Calculate total count and total gross amount for each sales type
            const totalSalesCount = formattedDataSource.length;

            const requestData = {
                invoice_no: transactionCode,
                quote_date: convertLocalToUTC(
                    formattedDate,
                    'YYYY-MM-DD',
                    'date',
                ),
                quote_time: convertLocalToUTC(endTime, 'HH:mm:ss', 'time'),
                customer: customer.id,
                customer_code: customer.code,
                exp_sale_date: convertLocalToUTC(
                    expSalesDate,
                    'YYYY-MM-DD',
                    'date',
                ),
                // exp_sale_date: expSalesDate,
                // exp_sale_date: expSalesDate,
                customer_name: customer.name,
                customer_street: customer.street,
                customer_building_number: customer.building_number,
                customer_city: customer.city,
                customer_postal_code: customer.postal_code,
                customer_district: customer.district,

                route: route,
                route_code: routeCode,
                route_name: routeName,
                salesman_id: salesmanId,
                salesman_name: salesmanName,
                salesman_code: salesmanCode,
                status: 'confirmed',

                vat_reg_no: vatRegNo,
                currency_id: currencyId,
                currency_code: currencyCode,
                currency: currency,
                remarks: remarks,

                transaction_started_time: convertLocalToUTC(
                    startTime,
                    'HH:mm:ss',
                    'time',
                ),
                transaction_ended_time: convertLocalToUTC(
                    endTime,
                    'HH:mm:ss',
                    'time',
                ),
                transaction_duration: parseFloat(duration),

                gross_amount: roundNumber(
                    totalGrossAmount,
                    roundingPrecision,
                    roundingRule,
                ),
                total_taxable_amount: roundNumber(
                    totalTaxableAmount,
                    roundingRule,
                    roundingPrecision,
                ),
                total_sales: roundNumber(
                    totalSales,
                    roundingPrecision,
                    roundingRule,
                ),
                net_amount: roundNumber(
                    totalNetAmount,
                    roundingPrecision,
                    roundingRule,
                ),
                total_discount: roundNumber(
                    totalDiscount,
                    roundingPrecision,
                    roundingRule,
                ),
                tax_amount: roundNumber(
                    totalVat,
                    roundingPrecision,
                    roundingRule,
                ),
                quote_time: convertLocalToUTC(currentTime, 'HH:mm:ss', 'time'),
                total_sales_count: totalSalesCount,
                quote_details: formattedDataSource.map(item => ({
                    detail_code: item.key.toString(),
                    quote_code: Math.floor(
                        Math.random() * (100 - 1 + 1) + 1,
                    ).toString(),
                    product: item.product,
                    product_code: item.product_code,
                    product_name: item.product_name,
                    cost_price: item.cost_price,
                    price_id: item.price_id,

                    requested_quantity: item.assigned_quantity,
                    assigned_quantity: item.assigned_quantity,

                    min_sales: item.min_sales,
                    max_discount: item.max_discount,
                    product_price: item.product_price,

                    gross_amount: item.gross_amount,
                    tax_1: item.tax_1,
                    tax_2: item.tax_2,
                    tax_perc: item.tax_perc,
                    tax_amount: item.tax_total,
                    group_id: item.group_id,
                    group_name: item.group_name,
                    group_code: item.group_code,
                    multipack_id: item.multipack.id,
                    multipack_code: item.multipack_code,

                    multipack_name: item.multipack.name,
                    base_multipack_id: item.base_multipack_id,
                    base_multipack_code: item.base_multipack_code,
                    base_multipack_name: item.base_multipack_name,
                    multiplier: item.multiplier,
                    discount: item.discount_perc,
                    discount_amt: item.discount !== '' ? item.discount : 0,
                    net_amount: item.net_amount,
                    total_taxable_amount: item.total_taxable_amount,

                    sale_price: item.salePrice,
                })),
            };

            // Post the data to the server
            const response = await postApiWithAuthToken(
                '/api/v1/admin/quotationsSummary',
                requestData,
            );

            if (!response.error) {
                message.success({content: response.data, key: 'form'});
                setConfirmFinal(true);
                setInvoiceData({
                    ...requestData,
                    quote_code: response?.data?.quote_code,
                });
                // history.push('/quotations-summary');
            } else {
                message.error({
                    content: 'Failed to submit data',
                    key: 'form',
                });
            }
        } catch (error) {
            message.error({
                content: 'An error occurred while submitting data',
                key: 'form',
            });
        }
    };

    //confirm for edit pending quotations

    const FinalSubmitPending = async () => {
        try {
            // Convert the dataSource array to the desired format
            const formattedDataSource = dataSource.map(item => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.assigned_quantity || 0);
                const vatPercentage = parseFloat(
                    item.tax_perc > 0 ? item.tax_perc : 0 || 0,
                );
                const discount = parseFloat(item.discount || 0);

                const grossAmount = salePrice * quantity;
                const totalTaxableAmount = grossAmount - discount;
                const vatAmount = totalTaxableAmount * vatPercentage;
                const netAmount = totalTaxableAmount + vatAmount;
                const discountPercentage = discount / grossAmount;

                if (item.fromApi) {
                    return {
                        ...item,
                        product: item.product.id, // Replace the product object with its ID
                        product_name: item.product.name,
                        product_code: item.product.code,
                        requested_quantity: 0,
                        multipack_name: item.multipack.name,
                        multipack_code: item.multipack.code,
                        multipack: item.multipack.id,
                        base_multipack_name: item.base_multipack.name,
                        base_multipack_id: item.base_multipack.id,
                        base_multipack_code: item.base_multipack.code,
                        multiplier: item.multipack.multiplier,
                        tax_total: roundNumber(
                            vatAmount,
                            roundingRule,
                            roundingPrecision + 1,
                        ),
                        gross_amount: roundNumber(
                            grossAmount,
                            roundingRule,
                            roundingPrecision + 1,
                        ),
                        net_amount: roundNumber(
                            netAmount,
                            roundingRule,
                            roundingPrecision + 1,
                        ),
                        total_taxable_amount: roundNumber(
                            totalTaxableAmount,
                            roundingRule,
                            roundingPrecision + 1,
                        ),
                        discount_perc: roundNumber(
                            discountPercentage,
                            roundingRule,
                            roundingPrecision + 1,
                        ),
                    };
                } else {
                    return {
                        ...item,
                        product: item.product.id,
                        product_name: item.product.name,
                        product_code: item.product.product_code,
                        multipack: item.multipack.id,
                        multipack_code: item.multipack.code,
                        multipack_name: item.multipack.name,
                        multiplier: item.multipack.multiplier,
                        base_multipack_id: item.multipack.base_multipack_id,
                        base_multipack_code: item.multipack.base_multipack_code,
                        base_multipack_name: item.multipack.base_multipack_name,
                        tax_total: roundNumber(
                            vatAmount,
                            roundingRule,
                            roundingPrecision + 1,
                        ),
                        gross_amount: roundNumber(
                            grossAmount,
                            roundingRule,
                            roundingPrecision + 1,
                        ),
                        net_amount: roundNumber(
                            netAmount,
                            roundingRule,
                            roundingPrecision + 1,
                        ),
                        total_taxable_amount: roundNumber(
                            totalTaxableAmount,
                            roundingRule,
                            roundingPrecision + 1,
                        ),
                        discount_perc: roundNumber(
                            discountPercentage,
                            roundingRule,
                            roundingPrecision + 1,
                        ),
                    };
                }
            });

            // Calculate totals
            // Calculate the total sales, total discount, total VAT, and total net amount
            const totalSales = dataSource.reduce((total, item) => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.assigned_quantity || 0);
                const gross = salePrice * quantity;
                return total + gross;
            }, 0);
            const totalGrossAmount = dataSource.reduce((total, item) => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.assigned_quantity || 0);
                const gross = salePrice * quantity;
                return total + gross;
            }, 0);

            const totalVat = dataSource.reduce((total, item) => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.assigned_quantity || 0);
                const tax = parseFloat(item.tax_perc || 0);
                const vat = salePrice * quantity * tax;
                return total + vat;
            }, 0);

            const totalDiscount = dataSource.reduce((total, item) => {
                const discount = parseFloat(item.discount || 0);
                return total + discount;
            }, 0);

            const totalNetAmount = dataSource.reduce((total, item) => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.assigned_quantity || 0);
                const vat = parseFloat(item.tax_perc || 0);
                const discount = parseFloat(item.discount || 0);
                const gross = salePrice * quantity;
                const taxable = gross - discount;
                const vatAmount = taxable * vat;
                const netAmount = taxable + vatAmount;
                return (
                    total +
                    roundNumber(netAmount, roundingRule, roundingPrecision + 1)
                );
            }, 0);
            const totalTaxableAmount = dataSource.reduce((total, item) => {
                const salePrice = parseFloat(item.salePrice || 0);
                const quantity = parseInt(item.assigned_quantity || 0);
                const discount = parseFloat(item.discount || 0);
                const gross = salePrice * quantity;
                const netAmount = gross - discount;
                return total + netAmount;
            }, 0);
            const currentTime = moment().format('HH:mm:ss');
            const endTimeEdit = moment().format('HH:mm:ss');
            const duration = moment
                .duration(
                    moment(endTimeEdit, 'HH:mm:ss').diff(
                        moment(editStart, 'HH:mm:ss'),
                    ),
                )
                .asSeconds();

            setEditDuration(duration);

            const date = moment(quotationSummary.quote_date).format(
                'YYYY-MM-DD',
            );
            const expDate = moment(
                quotationSummary.exp_sale_date,
                'DD-MM-YYYY',
            ).format('YYYY-MM-DD');
            // Calculate total count and total gross amount for each sales type
            const totalSalesCount = formattedDataSource.length;

            const requestData = {
                invoice_no: quotationSummary.invoice_no,
                quote_date: convertLocalToUTC(date, 'YYYY-MM-DD', 'date'),
                quote_time: convertLocalToUTC(endTime, 'HH:mm:ss', 'time'),
                customer: quotationSummary.customer,
                customer_code: quotationSummary.customer_code,
                exp_sale_date: convertLocalToUTC(
                    expSalesDate,
                    'YYYY-MM-DD',
                    'date',
                ),
                exp_sale_date: convertLocalToUTC(
                    expSalesDate,
                    'YYYY-MM-DD',
                    'date',
                ),
                customer_name: quotationSummary.customer_name,
                customer_street: quotationSummary.customer_street,
                customer_building_number:
                    quotationSummary.customer_building_number,
                customer_city: quotationSummary.customer_city,
                customer_postal_code: quotationSummary.customer_postal_code,
                customer_district: quotationSummary.customer_district,

                route: quotationSummary.route,
                route_code: quotationSummary.route_code,
                route_name: quotationSummary.route_name,
                salesman_id: quotationSummary.salesman,
                salesman_name: quotationSummary.salesman_name,
                salesman_code: quotationSummary.salesman_code,
                vat_reg_no: quotationSummary.vat_reg_no,
                currency_id: quotationSummary.currency_id,
                currency: quotationSummary.currency,
                currency_code: quotationSummary.currency_code,
                remarks: quotationSummary.remarks,

                transaction_started_time:
                    quotationSummary.transaction_started_time,
                transaction_ended_time: convertLocalToUTC(
                    endTimeEdit,
                    'HH:mm:ss',
                    'time',
                ),
                transaction_duration: parseFloat(duration),

                gross_amount: roundNumber(
                    totalGrossAmount,
                    roundingPrecision,
                    roundingRule,
                ),
                total_taxable_amount: roundNumber(
                    totalTaxableAmount,
                    roundingRule,
                    roundingPrecision,
                ),
                total_sales: roundNumber(
                    totalSales,
                    roundingPrecision,
                    roundingRule,
                ),
                net_amount: roundNumber(
                    totalNetAmount,
                    roundingPrecision,
                    roundingRule,
                ),
                total_discount: roundNumber(
                    totalDiscount,
                    roundingPrecision,
                    roundingRule,
                ),
                tax_amount: roundNumber(
                    totalVat,
                    roundingPrecision,
                    roundingRule,
                ),
                quote_time: convertLocalToUTC(currentTime, 'HH:mm:ss', 'time'),
                total_sales_count: totalSalesCount,
                quote_details: formattedDataSource.map(item => ({
                    detail_code: item.detail_code,
                    quote_code: item.quote_code,
                    product: item.product,
                    product_code: item.product_code,
                    product_name: item.product_name,
                    cost_price: item.cost_price,
                    tax_1: item.tax_1,
                    tax_2: item.tax_2,
                    group_code: item.group_code,
                    group_id: item.group_id,
                    group_name: item.group_name,
                    tax_perc: item.tax_perc,

                    price_id: item.price_id,

                    requested_quantity: item.fromApi
                        ? item.assigned_quantity
                        : 0,
                    assigned_quantity: item.assigned_quantity,

                    min_sales: item.min_sales,
                    max_discount: item.max_discount,
                    product_price: item.product_price,
                    discount_amt: item.discount,
                    gross_amount: item.gross_amount,
                    tax_amount: item.tax_total,

                    multipack_id: item.multipack,
                    multipack_code: item.multipack_code,
                    multipack_name: item.multipack_name,
                    base_multipack_id: item.base_multipack_id,
                    base_multipack_code: item.base_multipack_code,
                    base_multipack_name: item.base_multipack_name,
                    multiplier: item.multiplier,
                    discount: item.discount_perc,

                    sale_price: item.salePrice,
                    net_amount: item.net_amount,
                    total_taxable_amount: item.total_taxable_amount,
                })),
            };

            const response = await updateApiWithAuthToken(
                `/api/v1/admin/quotationsSummary/pending?invoice_no=${quote_code}`,
                requestData,
            );

            if (!response.error) {
                message.success({content: response.data, key: 'form'});
                setConfirmFinal(true);
                setInvoiceData(requestData);
            } else {
                message.error({content: 'Failed to submit data', key: 'form'});
            }
        } catch (error) {
            message.error({
                content: 'An error occurred while submitting data',
                key: 'form',
            });
        }
    };

    //customer Details
    //get customer list and route
    function getCustomers() {
        getApiWithAuthToken('/api/v1/admin/customers').then(res => {
            if (res.data) {
                setCustomerDet(res.data.customers);
                setCustData(false);
            } else {
                setCustomerDet([]);
            }
        });
    }
    //getRoutes
    function getRoute() {
        getApiWithAuthToken('/api/v1/admin/routes/transactionAllowed').then(
            res => {
                if (res.data?.length > 0) {
                    setRouteDet(res.data);
                } else {
                    setRouteDet([]);
                }
            },
        );
    }
    //currency List
    function getCurrency() {
        getApiWithAuthToken('/api/v1/admin/companyCurrencies').then(res => {
            if (res.data?.length > 0) {
                setCurrencyDet(res.data);
            } else {
                setCurrencyDet([]);
            }
        });
    }

    useEffect(() => {
        getCustomers();
        getRoute();
        getCompanyDetails();
        getCurrency();
    }, []);

    // Function to update the selected date
    const handleExpSalesDateChange = date => {
        if (date) {
            const parsedDate = new Date(date);

            const formattedDate = moment(parsedDate).format('YYYY-MM-DD');

            setExpSalesDate(formattedDate);
        }
    };

    // Edit function

    //Add Sales items
    const EditableContext = React.createContext(null);
    const EditableRow = ({index, ...props}) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false} onFinish={FinalSubmit}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };
    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        handleRemoveRow,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const [inputValue, setInputValue] = useState(record?.[dataIndex] ?? ''); // State to store input value with default value if undefined
        const [minValue, setMinValue] = useState(record?.min_sales || 0);

        const inputRef = useRef(null);
        const form = useContext(EditableContext);

        useEffect(() => {
            if (editing && inputRef.current) {
                inputRef.current.focus();
            }
        }, [editing]);
        const toggleEdit = () => {
            setEditing(!editing);
            setInputValue(record[dataIndex] ?? '');
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            });
        };
        const handleChange = e => {
            const {value} = e.target;
            setInputValue(Math.max(parseFloat(value), minValue));
        };

        const handleBlur = () => {
            if (inputValue !== record[dataIndex]) {
                handleSave({
                    ...record,
                    [dataIndex]: inputValue,
                });
            }
            setEditing(false);
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({
                    ...record,
                    ...values,
                });
            } catch (errInfo) {}
        };

        let childNode = children;

        if (editable && dataIndex === 'assigned_quantity') {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                        maxWidth: 200,
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ]}
                >
                    <InputNumber
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                        style={{textAlign: 'right'}}
                    />
                </Form.Item>
            ) : (
                <div
                    className='editable-cell-value-wrap'
                    style={{
                        paddingRight: 24,
                        height: '30px',
                        padding: '4px 11px',
                        border: '1px solid #d9d9d9',
                        borderRadius: '2px',
                        textAlign: 'right',
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        } else if (editable && dataIndex === 'salePrice') {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                        maxWidth: 200,
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                const minValue = record['min_sales']; // Access minSalesPrice from the record object

                                if (
                                    !isNaN(minValue) &&
                                    !isNaN(value) &&
                                    value >= minValue
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(
                                        `The ${title} must be at least ${minValue}.`,
                                    ),
                                );
                            },
                        }),
                    ]}
                    initialValue={record[dataIndex]}
                >
                    <Input
                        type='number'
                        ref={inputRef}
                        onPressEnter={toggleEdit}
                        onBlur={handleBlur}
                        value={inputValue}
                        onChange={handleChange}
                        style={{textAlign: 'right'}}
                    />
                </Form.Item>
            ) : (
                <div
                    className='editable-cell-value-wrap'
                    style={{
                        paddingRight: 24,
                        height: '30px',
                        padding: '4px 11px',
                        border: '1px solid #d9d9d9',
                        borderRadius: '2px',
                        textAlign: 'right',
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        } else if (editable && dataIndex === 'action') {
            childNode = (
                <Button type='link' danger>
                    Remove
                </Button>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    //Edit table components

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    //state for discount editable cells

    const [editingDiscount, setEditingDiscount] = useState(true);
    const [totalDiscounValue, setTotalDiscountValue] = useState(0);
    //productlist modal state
    const [productModal, setProductModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    //multipack list state
    const [selectedMultipackValues, setSelectedMultipackValues] = useState({});

    const [currentlyEditingKey, setCurrentlyEditingKey] = useState(null);

    //productlist modal view
    const inputRef = useRef(null);

    useEffect(() => {
        if (productModal) {
            inputRef.current.focus(); // Focus on the input field
        }
    }, [productModal]);

    const handleProductModal = (record, rowIndex) => {
        setSelectedProduct({...record, rowIndex});
        setProductModal(true);
    };

    const handleEditDiscount = async (key, value, max_discount) => {
        // Convert value to a number
        value = parseFloat(value);

        // Check if the entered value is a valid number
        if (isNaN(value)) {
            // If the entered value is not a number, set it to 0
            value = 0;
        }

        // Check if the entered value exceeds the maxDiscount
        const isExceedingMax = max_discount && value > max_discount;
        const tooltipValue = isExceedingMax ? max_discount : value;

        // // Check if the entered value exceeds the maxDiscount
        // if (max_discount && value > max_discount) {

        //     value = max_discount;
        // }

        // Create a copy of the dataSource to avoid mutating the original data
        const newData = dataSource.map(item => {
            if (item.key === key) {
                return {...item, discount: tooltipValue};
            }
            return item;
        });

        // Calculate total discount value
        const totalDiscountValue = newData.reduce(
            (total, item) => total + item.discount,
            0,
        );

        setTotalDiscountValue(totalDiscountValue);
        setCurrentlyEditingKey(key);

        // Reset total discount value if any discount exceeds maxDiscount
        const exceedsMaxDiscount = newData.some(
            item => item.discount > (item.max_discount || Infinity),
        );

        if (exceedsMaxDiscount) {
            setTotalDiscountValue(0);
            message.error('Discount exceeds maximum allowed value');
        } else {
            // Update the dataSource state only if no discount exceeds maxDiscount
            setDataSource(newData);
        }
    };

    const handleProductModalclose = () => {
        setProductModal(false);
        setSearchInput('');
        setFilterOption('name');
    };
    const handleProductSelect = async productId => {
        const selected = productList.find(product => product.id === productId);
        setSelectProductId(selected.id);
        const tax1 = typeof selected.tax_1 === 'number' ? selected.tax_1 : 0;
        const tax2 = typeof selected.tax_2 === 'number' ? selected.tax_2 : 0;
        const totalTax = tax1 + tax2;

        // Make API call to fetch multipack options
        try {
            const response = await getApiWithAuthToken(
                `/api/v1/admin/productMultipacks/price?product_id=${productId}&priceExist=1`,
            );
            if (response.data) {
                const multipackOptions = response.data.map(multipack => ({
                    id: multipack.id,
                    code: multipack.code,
                    name: multipack.name,
                    base_multipack_id: multipack.base_multipack_id,
                    base_multipack_code: multipack.base_multipack_code,
                    base_multipack_name: multipack.base_multipack_name,
                    multiplier: multipack.multiplier,
                }));

                const updatedDataSource = dataSource.map(item => {
                    if (item.key === selectedProduct.rowIndex) {
                        return {
                            ...item,
                            product: selected,
                            tax_1: tax1,
                            tax_2: tax2,
                            tax_perc: totalTax,
                            multipack: multipackOptions, // Save multipack options in the item
                        };
                    }
                    return item;
                });

                setDataSource(updatedDataSource);
            }
        } catch (error) {
            message.error('Failed to fetch multipack options');
        }

        setSelectedProduct(null); // Clear selectedProduct state after updating row
        setProductModal(false);
        setFilterOption('name');
    };

    // Inside setEditingDiscount function
    const toggleEditingDiscount = value => {
        // Reset total discount value and discount fields when turning off editing mode
        if (!value) {
            const resetDiscountData = dataSource.map(item => {
                return {...item, discount: 0};
            });
            setDataSource(resetDiscountData);
            setTotalDiscountValue(0);
        }
        setEditingDiscount(value);
    };

    const customerField = (
        <Form.Item name='customer' label='Customer'>
            {quote_code ? (
                quotationSummary.customer_name
            ) : (
                <Select
                    style={{width: '200px'}}
                    loading={custData}
                    showSearch
                    disabled={confirm}
                    placeholder='Select Customer'
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={customerId => {
                        setStartTime(moment().format('HH:mm:ss'));
                        const selectedCustomer = customerDet.find(
                            customer => customer.id === customerId,
                        );
                        if (selectedCustomer) {
                            setCustomer(selectedCustomer);
                        }
                    }}
                >
                    {customerDet.map(customer => (
                        <Select.Option key={customer.id} value={customer.id}>
                            {customer.name}
                        </Select.Option>
                    ))}
                </Select>
            )}
        </Form.Item>
    );
    const routeField = (
        <Form.Item name='route_id' label='Route'>
            {quote_code ? (
                quotationSummary.route_name
            ) : (
                <Select
                    style={{width: '200px'}}
                    showSearch
                    disabled={confirm}
                    placeholder='Select Route'
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                        const childrenValue =
                            option?.children?.toString().toLowerCase() || '';
                        return childrenValue.indexOf(input.toLowerCase()) >= 0;
                    }}
                    onSelect={routeId => {
                        const selectedRoute = routeDet.find(
                            route => route.id === routeId,
                        );
                        if (selectedRoute) {
                            setRoute(routeId);
                            setRouteName(selectedRoute.name);
                            setRouteCode(selectedRoute.code);
                            setSalesmanId(selectedRoute.salesman_id);
                            setSalesmanName(selectedRoute.salesman);
                            setSalesmanCode(selectedRoute.salesman_code);
                        }
                    }}
                >
                    {routeDet.map(route => (
                        <Select.Option
                            key={route.id}
                            value={route.id}
                            disabled={route.available !== true}
                        >
                            {route.available !== true ? (
                                <Tooltip
                                    title='Currently live routes are disabled'
                                    color='geekblue'
                                >
                                    <span>
                                        {route.code
                                            ? `${route.code}_${route.name}`
                                            : route.name}
                                    </span>
                                </Tooltip>
                            ) : route.code ? (
                                `${route.code}_${route.name}`
                            ) : (
                                route.name
                            )}
                        </Select.Option>
                    ))}
                </Select>
            )}
        </Form.Item>
    );

    //check for priceType and price

    const handleMultipackChange = async (recordKey, value) => {
        setSelectedMultipackValues(prevState => ({
            ...prevState,
            [recordKey]: value,
        }));

        try {
            const res = await getApiWithAuthToken(
                `/api/v1/admin/priceKeyGroups/price/${productId}/${customer.id}/${route}/${value}`,
            );

            if (!res.error && res.data && res.data.length > 0) {
                const priceData = res.data[0];
                const selectedPrice = priceData.sales || 0;
                const minValue = priceData.min_sales || 0;
                const multipackCode = priceData.multipack_code;
                const groupId = priceData.group_id;
                const groupName = priceData.name;
                const groupCode = priceData.group_code;

                const updatedDataSource = dataSource.map(item => {
                    if (item.key === recordKey) {
                        // Modify this part to save entire multipack details
                        const selectedMultipack = multipackList.find(
                            multipack => multipack.id === value,
                        );
                        return {
                            ...item,
                            multipack: selectedMultipack, // Save entire multipack details
                            salePrice: selectedPrice,

                            product_price: selectedPrice,
                            min_sales: minValue,
                            max_discount: priceData.max_discount || null,
                            cost_price: priceData.cost_price,
                            price_id: priceData.id,
                            group_id: groupId,
                            group_name: groupName,
                            group_code: groupCode,
                            multipack_code: multipackCode,
                        };
                    }
                    return item;
                });

                setDataSource(updatedDataSource);
            } else {
            }
        } catch (error) {}
    };

    //remove function of each row
    const handleRemoveRow = async key => {
        try {
            // Filter out the removed row from dataSource
            const updatedDataSource = dataSource.filter(
                item => item.key !== key,
            );
            setDataSource(updatedDataSource);

            // Remove the corresponding entry from selectedMultipackValues
            setSelectedMultipackValues(prevState => {
                const newState = {...prevState};
                delete newState[key];
                return newState;
            });
        } catch (error) {}
    };

    //rounding numbers

    function roundNumber(value, roundingRule, roundingPrecision) {
        if (roundingRule === 'round') {
            return (
                Math.round(value * Math.pow(10, roundingPrecision + 1)) /
                Math.pow(10, roundingPrecision + 1)
            );
        } else if (roundingRule === 'ceil') {
            return (
                Math.ceil(value * Math.pow(10, roundingPrecision + 1)) /
                Math.pow(10, roundingPrecision + 1)
            );
        } else if (roundingRule === 'floor') {
            return (
                Math.floor(value * Math.pow(10, roundingPrecision + 1)) /
                Math.pow(10, roundingPrecision + 1)
            );
        } else {
            // Default to rounding to 2
            return Math.round(value * 100) / 100;
        }
    }

    //add form table columns

    const formColumns = [
        {
            title: 'Product',
            dataIndex: 'product',
            width: '15%',
            editable: true,
            render: (_, record) => {
                if (record.fromApi) {
                    return record.product?.name;
                } else {
                    return (
                        <span
                            onClick={() =>
                                handleProductModal(record, record.key)
                            }
                        >
                            {record.product ? (
                                record.product.name
                            ) : (
                                <Button>Select Product</Button>
                            )}
                        </span>
                    );
                }
            },
        },
        {
            title: 'Multipack',
            dataIndex: 'multipack',
            editable: true,
            width: '10%',
            render: (_, record) => {
                if (record.fromApi) {
                    return record.multipack?.name;
                } else {
                    const rowIndex = dataSource.findIndex(
                        item => item.key === record.key,
                    );
                    return (
                        <Select
                            disabled={!record.product}
                            value={selectedMultipackValues[rowIndex]} // Set the selected value based on the row key
                            onChange={value =>
                                handleMultipackChange(record.key, value)
                            }
                            style={{width: '100%'}}
                        >
                            {multipackList.map(multipack => (
                                <Select.Option
                                    key={multipack.id}
                                    value={multipack.id}
                                >
                                    {multipack.name}
                                </Select.Option>
                            ))}
                        </Select>
                    );
                }
            },
        },
        {
            title: 'Req Quantity',
            dataIndex: 'requested_quantity',
            width: '11%',
            editable: (_, record) => !record.fromApi,
            render: text => <div style={{textAlign: 'right'}}>{text}</div>,
        },

        {
            title: 'Assi Quantity',
            dataIndex: 'assigned_quantity',
            width: '11%',
            editable: true,
        },

        {
            title: 'Price',
            dataIndex: 'salePrice',
            key: 'price',
            editable: true,
            width: '10%',
            render: (text, record) => {
                const {cost_price, salePrice, product_price} = record;

                // Get all valid prices
                const validPrices = [
                    cost_price,
                    salePrice,
                    product_price,
                ].filter(p => typeof p === 'number' && p > 0);

                // Get the highest price or return '-' if no valid prices
                if (validPrices.length === 0) {
                    return '-';
                }

                const highestPrice = Math.max(...validPrices);

                // Return formatted price
                return `${highestPrice}`;
            },
        },

        {
            title: (
                <span>
                    Discount
                    <Button
                        type='link'
                        size='small'
                        onClick={() => toggleEditingDiscount(!editingDiscount)}
                    >
                        {!editingDiscount ? 'Edit' : 'Reset'}
                    </Button>
                </span>
            ),
            dataIndex: 'discount',
            editable: editingDiscount,
            width: '10%',
            render: (_, record) => {
                const {key, discount, max_discount, product} = record;
                const totalItems = dataSource.length;
                let updatedDiscount = discount; // Start with the saved discount value

                // Only calculate divided discount if a product is selected
                if (product && totalDiscounValue) {
                    // Divide total discount by the number of items
                    let dividedDiscount = totalDiscounValue / totalItems;

                    // Ensure dividedDiscount doesn't exceed max_discount
                    if (max_discount && dividedDiscount > max_discount) {
                        dividedDiscount = max_discount;
                    }

                    // Use divided discount if editing is not enabled
                    updatedDiscount = editingDiscount
                        ? discount
                        : dividedDiscount;
                }

                // Format the discount for display
                const formattedDiscount = roundNumber(
                    updatedDiscount,
                    roundingPrecision,
                    roundingRule,
                );

                const inputComponent = (
                    <Input
                        value={formattedDiscount.toString()} // Display the correct discount
                        onChange={e =>
                            handleEditDiscount(
                                key,
                                e.target.value,
                                max_discount,
                            )
                        }
                        ref={inputRef => {
                            if (inputRef && key === currentlyEditingKey) {
                                inputRef.focus();
                            }
                        }}
                        onBlur={() => setCurrentlyEditingKey(null)}
                        style={{textAlign: 'right'}}
                        disabled={!product} // Disable if no product is selected
                    />
                );

                return editingDiscount ? (
                    <Tooltip
                        title={
                            max_discount
                                ? `Max discount is: ${max_discount}`
                                : null
                        }
                        trigger={['focus', 'hover']}
                        placement='topRight'
                    >
                        {inputComponent}
                    </Tooltip>
                ) : (
                    <span onClick={() => setCurrentlyEditingKey(key)}>
                        {formattedDiscount}
                    </span>
                );
            },
        },
        {
            title: 'Gross Amount',
            dataIndex: 'gross_amount',
            width: '13%',
            render: (_, record) => {
                const {
                    cost_price,
                    salePrice,
                    product_price,
                    assigned_quantity = 0,
                } = record;

                // Get all valid prices (numbers greater than 0)
                const validPrices = [
                    cost_price,
                    salePrice,
                    product_price,
                ].filter(p => typeof p === 'number' && p > 0);

                // Get the highest price or default to 0 if no valid prices
                const price =
                    validPrices.length > 0 ? Math.max(...validPrices) : 0;

                const gross = price * assigned_quantity;

                return (
                    <span style={{display: 'block', textAlign: 'right'}}>
                        {roundNumber(gross, roundingRule, roundingPrecision)}
                    </span>
                );
            },
        },

        {
            title: 'Tot Tax Amt',
            dataIndex: 'total_taxable_amt',
            width: '13%',
            render: (_, record) => {
                const {
                    cost_price,
                    salePrice,
                    product_price,
                    assigned_quantity = 0,
                    discount = 0,
                } = record;

                // Create array of price objects with their types
                const priceOptions = [
                    {type: 'cost_price', value: cost_price},
                    {type: 'salePrice', value: salePrice},
                    {type: 'product_price', value: product_price},
                ];

                // Filter valid prices and find highest
                const validPrices = priceOptions.filter(
                    p => typeof p.value === 'number' && p.value > 0,
                );

                const highestPrice =
                    validPrices.length > 0
                        ? validPrices.reduce((max, current) =>
                              current.value > max.value ? current : max,
                          )
                        : {type: 'none', value: 0};

                // Calculate gross amount with highest price
                const gross = highestPrice.value * assigned_quantity;

                // Optional: log which price was used
                console.log(
                    `Using ${highestPrice.type} (${highestPrice.value}) for tax calculation`,
                );

                // Calculate total taxable amount
                const total_taxable_amt = roundNumber(
                    gross - discount,
                    roundingRule,
                    roundingPrecision,
                );

                return (
                    <span style={{display: 'block', textAlign: 'right'}}>
                        {total_taxable_amt}
                    </span>
                );
            },
        },

        {
            title: 'Tax Amt.',
            dataIndex: 'vat',
            width: '10%',
            render: (_, record) => {
                const {
                    cost_price,
                    salePrice,
                    product_price,
                    assigned_quantity = 0,
                    tax_perc = 0,
                    discount = 0,
                } = record;

                // Get all valid prices
                const validPrices = [
                    cost_price,
                    salePrice,
                    product_price,
                ].filter(p => typeof p === 'number' && p > 0);

                // Get highest price or default to 0
                const price =
                    validPrices.length > 0 ? Math.max(...validPrices) : 0;

                const totalTaxable = price * assigned_quantity - discount;
                const vat = roundNumber(
                    totalTaxable * tax_perc,
                    roundingRule,
                    roundingPrecision,
                );

                return (
                    <span style={{display: 'block', textAlign: 'right'}}>
                        {vat}
                    </span>
                );
            },
        },

        {
            title: 'Net Amount',
            dataIndex: 'total',
            width: '10%',
            render: (_, record) => {
                const {
                    cost_price,
                    salePrice,
                    product_price,
                    tax_perc = 0,
                    discount = 0,
                    assigned_quantity = 0,
                } = record;

                // Get all valid prices
                const validPrices = [
                    cost_price,
                    salePrice,
                    product_price,
                ].filter(p => typeof p === 'number' && p > 0);

                // Get highest price or default to 0
                const price =
                    validPrices.length > 0 ? Math.max(...validPrices) : 0;

                // Net Amount Calculation
                const gross = price * assigned_quantity;
                const totalTaxable = gross - discount;
                const taxAmount = totalTaxable * tax_perc;
                const netAmount = totalTaxable + taxAmount;

                return (
                    <span style={{display: 'block', textAlign: 'right'}}>
                        {roundNumber(
                            netAmount,
                            roundingRule,
                            roundingPrecision,
                        )}
                    </span>
                );
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '5%',
            render: (_, record) => (
                <Button
                    type='link'
                    danger
                    onClick={() => handleRemoveRow(record.key)}
                >
                    Remove
                </Button>
            ),
        },
    ];

    const [dataSource, setDataSource] = useState([]);

    const [productList, setProductList] = useState([]);
    const [multipackList, setMultipackList] = useState([]);
    const [count, setCount] = useState(0);
    const [showTable, setShowTable] = useState(false);

    //product modal filter states
    const [searchInput, setSearchInput] = useState('');
    const [filterOption, setFilterOption] = useState('name');

    //multipack

    const [multipackNames, setMultipackNames] = useState({});

    //collection modal

    //fetch Products and multipacks
    useEffect(() => {
        fetchProductList();
    }, []);
    useEffect(() => {
        fetchMultipackList();
    }, [productId]);

    const fetchProductList = async () => {
        try {
            const response = await getApiWithAuthToken(
                '/api/v1/admin/products/active?priceExist=1',
            );
            if (response.data) {
                setProductList(response.data);
            }
        } catch (error) {}
    };
    const fetchMultipackList = async () => {
        try {
            const response = await getApiWithAuthToken(
                `/api/v1/admin/productMultipacks/price?product_id=${productId}&priceExist=1`,
            );
            if (response.data) {
                const newMultipackNames = {};
                response.data.forEach(multipack => {
                    newMultipackNames[multipack.id] = multipack.name;
                });
                setMultipackNames(newMultipackNames);

                setMultipackList(response.data);
            }
        } catch (error) {}
    };

    const handleAdd = () => {
        const newData = {
            key: dataSource.length,

            product: '',
            multipack: '',
            requested_quantity: '',
            assigned_quantity: 0,
            salePrice: '',
            cost_price: '',
            product_price: '',
            tax_1: '',
            tax_2: '',
            discount: '',
            total: '',
            fromApi: false,
        };
        setDataSource([...dataSource, newData]);
        setCount(count + 1);
        setShowTable(true);
        setEditable(true);
    };

    const handleSave = async updatedRow => {
        try {
            const newData = [...dataSource];
            const index = newData.findIndex(
                item => updatedRow.key === item.key,
            );
            const oldRow = newData[index];

            // Get all valid prices
            const validPrices = [
                updatedRow.cost_price,
                updatedRow.salePrice,
                updatedRow.product_price,
            ].filter(p => typeof p === 'number' && p > 0);

            // Get highest price or default to 0
            const price = validPrices.length > 0 ? Math.max(...validPrices) : 0;

            const grossAmount = price * updatedRow.assigned_quantity;

            // Calculate vat
            const vat = roundNumber(
                grossAmount * (vatSum !== undefined ? vatSum : vatamt),
                roundingPrecision,
                roundingRule,
            );

            // Calculate total taxable amount and net amount
            const totalTaxable = roundNumber(
                grossAmount - updatedRow.discount,
                roundingPrecision,
                roundingRule,
            );

            const netAmount = roundNumber(
                grossAmount + vat - updatedRow.discount,
                roundingPrecision,
                roundingRule,
            );

            // Calculate discount percentage
            const discountPercentage = roundNumber(
                grossAmount > 0 ? (updatedRow.discount / grossAmount) * 100 : 0,
                roundingPrecision,
                roundingRule,
            );

            // Update row with new calculations
            const updatedRowWithValues = {
                ...oldRow,
                ...updatedRow,
                vat_amt: vat,
                net_amount: netAmount,
                total_taxable_amount: totalTaxable,
                discount_perc: discountPercentage,
                // You might want to store which price was used
                sale_price: price,
            };

            newData.splice(index, 1, updatedRowWithValues);
            setDataSource(newData);
        } catch (error) {
            console.error('Error updating row:', error);
            // Handle error appropriately
        }
    };

    const columnss = formColumns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave, // Pass the handleSave function to each editable column
            }),
        };
    });

    //final Value calculation for table summary
    const summaryRow = () => {
        const totalSales = dataSource.reduce((total, item) => {
            // Get all valid prices
            const validPrices = [
                parseFloat(item.cost_price || 0),
                parseFloat(item.salePrice || 0),
                parseFloat(item.product_price || 0),
            ].filter(p => p > 0);

            const price = validPrices.length > 0 ? Math.max(...validPrices) : 0;
            const quantity = parseInt(item.assigned_quantity || 0);
            const gross = price * quantity;

            return (
                total + roundNumber(gross, roundingRule, roundingPrecision + 1)
            );
        }, 0);

        const totalVat = dataSource.reduce((total, item) => {
            const validPrices = [
                parseFloat(item.cost_price || 0),
                parseFloat(item.salePrice || 0),
                parseFloat(item.product_price || 0),
            ].filter(p => p > 0);

            const price = validPrices.length > 0 ? Math.max(...validPrices) : 0;
            const quantity = parseInt(item.assigned_quantity || 0);
            const discount = parseFloat(item.discount || 0);
            const vat = price * quantity - discount;

            return (
                total + roundNumber(vat, roundingRule, roundingPrecision + 1)
            );
        }, 0);

        const totalTax = dataSource.reduce((total, item) => {
            const validPrices = [
                parseFloat(item.cost_price || 0),
                parseFloat(item.salePrice || 0),
                parseFloat(item.product_price || 0),
            ].filter(p => p > 0);

            const price = validPrices.length > 0 ? Math.max(...validPrices) : 0;
            const quantity = parseInt(item.assigned_quantity || 0);
            const discount = parseFloat(item.discount || 0);
            const tax = parseFloat(item.tax_perc || 0);

            const gross = price * quantity;
            const totalTaxable = gross - discount;
            const vat = totalTaxable * tax;

            return (
                total + roundNumber(vat, roundingRule, roundingPrecision + 1)
            );
        }, 0);

        const totalDiscount = dataSource.reduce((total, item) => {
            const discount = parseFloat(item.discount || 0);
            return (
                total +
                roundNumber(discount, roundingRule, roundingPrecision + 1)
            );
        }, 0);

        const totalNetAmount = dataSource.reduce((total, item) => {
            const validPrices = [
                parseFloat(item.cost_price || 0),
                parseFloat(item.salePrice || 0),
                parseFloat(item.product_price || 0),
            ].filter(p => p > 0);

            const price = validPrices.length > 0 ? Math.max(...validPrices) : 0;
            const quantity = parseInt(item.assigned_quantity || 0);
            const vat = parseFloat(item.tax_perc || 0);
            const discount = parseFloat(item.discount || 0);

            const gross = price * quantity;
            const taxable = gross - discount;
            const vatAmount = taxable * vat;
            const netAmount = taxable + vatAmount;

            return (
                total +
                roundNumber(netAmount, roundingRule, roundingPrecision + 1)
            );
        }, 0);
        const handleTotalDiscountChange = async value => {
            // Convert value to a number
            value = parseFloat(value);

            // Ensure the total discount value is valid
            if (isNaN(value) || value < 0) {
                value = 0;
            }

            // Calculate the total quantity of all products
            const totalQuantity = dataSource.length;

            // Calculate the individual discount per item
            const individualDiscount =
                totalQuantity > 0 ? value / totalQuantity : 0;

            // Update the discount value for each row in the dataSource with rounding
            const newData = dataSource.map(item => {
                const {max_discount} = item;
                let calculatedDiscount = roundNumber(
                    individualDiscount,
                    roundingPrecision,
                    roundingRule,
                );

                // Check if maxDiscount exists and the calculated discount exceeds it
                if (
                    max_discount !== null &&
                    calculatedDiscount > max_discount
                ) {
                    calculatedDiscount = max_discount; // Apply the maxDiscount limit
                }

                // Calculate vat and total based on updatedRow values
                const vat = roundNumber(
                    item.salePrice * item.assigned_quantity * vatamt,
                    roundingPrecision,
                    roundingRule,
                );
                const total = roundNumber(
                    item.salePrice * item.assigned_quantity +
                        vat -
                        calculatedDiscount,
                    roundingPrecision,
                    roundingRule,
                );

                // Calculate discount percentage
                const discountPercentage = roundNumber(
                    (calculatedDiscount /
                        (item.salePrice * item.assigned_quantity)) *
                        100,
                    roundingPrecision,
                    roundingRule,
                );
                const totalTaxable = roundNumber(
                    item.salePrice * item.assigned_quantity * vatamt,
                    roundingRule,
                    roundingPrecision,
                );

                return {
                    ...item,
                    discount_amount: calculatedDiscount,
                    vat_amt: vat,
                    net_amount: total,
                    discount_perc: discountPercentage,
                    total_taxable_amount: totalTaxable,
                };
            });

            // Update the total discount value state
            setTotalDiscountValue(value);

            // Update the dataSource state with the new discount values
            setDataSource(newData);
        };

        return (
            <>
                <Table.Summary>
                    <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell colSpan={2} align='end'>
                            Sales Total
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='right'>
                            <Text strong>
                                {roundNumber(
                                    totalSales,
                                    roundingPrecision,
                                    roundingRule,
                                )}
                            </Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell colSpan={2} align='end'>
                            Total Discount
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='right'>
                            {editingDiscount ? (
                                <Text strong>
                                    {roundNumber(
                                        totalDiscount,
                                        roundingPrecision,
                                        roundingRule,
                                    )}
                                </Text>
                            ) : (
                                <Input
                                    value={roundNumber(
                                        totalDiscounValue,
                                        roundingPrecision,
                                        roundingRule,
                                    )}
                                    onChange={e => {
                                        handleTotalDiscountChange(
                                            e.target.value,
                                        );
                                    }}
                                />
                            )}
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell colSpan={2} align='end'>
                            Total Taxable Amt.
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='right'>
                            <Text strong>
                                {roundNumber(
                                    isNaN(totalVat) ? 0 : totalVat,
                                    roundingPrecision,
                                    roundingRule,
                                )}
                            </Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell colSpan={2} align='end'>
                            {' '}
                            Total Tax Amt.
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='right'>
                            <Text strong>
                                {roundNumber(
                                    totalTax,
                                    roundingPrecision,
                                    roundingRule,
                                )}
                            </Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell colSpan={2} align='end'>
                            Net Total
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='right'>
                            <Text strong>
                                {roundNumber(
                                    totalNetAmount,
                                    roundingPrecision,
                                    roundingRule,
                                )}
                            </Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                </Table.Summary>
            </>
        );
    };

    //submitting Function

    const handleSubmit = quote_code ? FinalSubmitPending : FinalSubmit;

    return (
        <div>
            <Button
                style={{border: 0}}
                onClick={() => {
                    quote_code
                        ? history.push(`pending-quotations`)
                        : history.push(`quotations-summary`);
                }}
            >
                <LeftOutlined />
            </Button>
            <Form form={form} name='new-quotation' layout='inline'>
                <Descriptions
                    title={
                        <div style={{textAlign: 'left'}}>
                            {quote_code
                                ? 'Edit Quotation'
                                : 'Add New Quotation'}
                        </div>
                    }
                    bordered
                    style={{margin: '8px', width: '100%'}}
                    column={{
                        xxl: 3,
                        xl: 3,
                        lg: 3,
                        md: 3,
                        sm: 2,
                        xs: 1,
                    }}
                >
                    <Form.Item
                        label='Invoice No'
                        initialValue={
                            quote_code
                                ? quotationSummary.invoice_no
                                : transactionCode
                        }
                        name='invoice_no'
                    >
                        {quote_code
                            ? quotationSummary.invoice_no
                            : transactionCode}
                    </Form.Item>
                    {!quote_code && (
                        <Form.Item name='date' label='Date'>
                            <DatePicker
                                value={moment()}
                                disabled
                                style={{pointerEvents: 'none'}}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Date is required',
                                    },
                                ]}
                            />
                        </Form.Item>
                    )}

                    <Form.Item
                        name='exp_sales_date'
                        label='Expected Sales Date'
                        rules={[
                            {
                                required: true,
                                message: 'Date is required',
                            },
                        ]}
                    >
                        <DatePicker
                            onChange={handleExpSalesDateChange}
                            size='middle'
                            defaultValue={
                                quote_code
                                    ? moment(quotationSummary.exp_sale_date)
                                    : undefined
                            }
                            disabledDate={currentDate => {
                                return (
                                    currentDate &&
                                    currentDate < moment().startOf('day')
                                );
                            }}
                        />
                    </Form.Item>

                    {customerField}

                    {routeField}

                    <Form.Item
                        name='salesman'
                        initialValue={salesmanName}
                        label='Salesman'
                    >
                        {quote_code
                            ? quotationSummary.salesman_name
                            : salesmanName}
                    </Form.Item>

                    <Form.Item
                        name='vat_reg_no'
                        initialValue={vatRegNo}
                        label='Tax No.'
                    >
                        {quote_code ? quotationSummary.vat_reg_no : vatRegNo}
                    </Form.Item>

                    <Form.Item label='Currency' name='currency'>
                        {quote_code ? quotationSummary.currency : currency}
                    </Form.Item>

                    {!quote_code && (
                        <Form.Item name='remarks' label='Remarks'>
                            <TextArea
                                disabled={confirm}
                                style={{width: '50%'}}
                                value={remarks}
                                onChange={e => setRemarks(e.target.value)}
                            />
                        </Form.Item>
                    )}
                </Descriptions>
            </Form>

            <div style={{marginTop: 16}}>
                <Row gutter={[16, 16]} justify='end'>
                    {(showTable || quote_code) && (
                        <Col>
                            <Button
                                type='primary'
                                style={{
                                    marginRight: 8,
                                    // border: '1px solid black',
                                }}
                                onClick={handleSubmit}
                            >
                                Confirm
                            </Button>
                        </Col>
                    )}
                    <Col>
                        {(quote_code || route) && (
                            <Button
                                onClick={handleAdd}
                                type='default'
                                style={{marginLeft: 8}}
                            >
                                Add Product
                            </Button>
                        )}
                    </Col>
                </Row>
            </div>

            {(showTable || quote_code) && (
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    loading={loading}
                    columns={columnss}
                    pagination={false}
                    style={{marginBottom: '10px', marginTop: '50px'}}
                    summary={summaryRow}
                />
            )}

            {/* modal window for productSelect */}
            <Modal
                open={productModal}
                onCancel={() => {
                    handleProductModalclose();
                    setSearchInput('');
                    setFilterOption('name'); // Reset filter option
                }}
                footer={null}
                style={{top: '10%'}} // Adjust the top value as needed
            >
                <h2>Product List</h2>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Input
                        placeholder={`Search By ${filterOption} ....`}
                        value={searchInput}
                        onChange={e => setSearchInput(e.target.value)}
                        style={{marginRight: 10}}
                        ref={inputRef}
                    />
                    <Select
                        value={filterOption}
                        onChange={value => setFilterOption(value)}
                        style={{marginRight: 10}}
                    >
                        <Select.Option value='name'>Name</Select.Option>
                        <Select.Option value='code'>Code</Select.Option>
                        <Select.Option value='category'>Category</Select.Option>
                    </Select>
                    <Button
                        onClick={() => {
                            setSearchInput('');
                            setFilterOption('name'); // set default as name
                        }}
                    >
                        Reset
                    </Button>
                </div>
                <div style={{maxHeight: 300, overflowY: 'scroll'}}>
                    {productList
                        .filter(product => {
                            const filterValue =
                                filterOption === 'name'
                                    ? product.name
                                    : filterOption === 'code'
                                      ? product.code
                                      : product.category;

                            // Check if filterValue is a string before calling toLowerCase()
                            if (typeof filterValue === 'string') {
                                return filterValue
                                    .toLowerCase()
                                    .includes(searchInput.toLowerCase());
                            }
                            // If filterValue is not a string, don't include it in the filtered list
                            return false;
                        })
                        .map(product => (
                            <div
                                key={product.id}
                                style={{
                                    marginBottom: 10,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    border: '1px solid #adbab8',
                                    padding: '5px',
                                    borderRadius: '4px',
                                    margin: '5px',
                                }}
                            >
                                <span>
                                    {product.name} ({product.code}) -{' '}
                                    {product.category}
                                </span>
                                <Button
                                    type='primary'
                                    onClick={() =>
                                        handleProductSelect(product.id)
                                    }
                                    style={{marginLeft: 10}}
                                >
                                    Add
                                </Button>
                            </div>
                        ))}
                </div>
            </Modal>
            {confirmFinal && <InvoiceView Invoice={invoiceData} />}
        </div>
    );
};

export default AddQuotations;
