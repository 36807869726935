export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';

export const UPDATE_COMPANY_SETTINGS = 'UPDATE_COMPANY_SETTINGS';
export const UPDATE_ONBOARDING_STATUS = 'UPDATE_ONBOARDING_STATUS';

// Table state
export const SET_SALES_SUMMARY_TABLE_PARAMS = 'SET_SALES_SUMMARY_TABLE_PARAMS';
export const SET_COLLECTIONS_SUMMARY_TABLE_PARAMS =
    'SET_COLLECTIONS_SUMMARY_TABLE_PARAMS';
export const SET_QUOTATIONS_SUMMARY_TABLE_PARAMS =
    'SET_QUOTATIONS_SUMMARY_TABLE_PARAMS';
export const SET_PENDING_QUOTATIONS_SUMMARY_TABLE_PARAMS =
    'SET_PENDING_QUOTATIONS_SUMMARY_TABLE_PARAMS';
export const SET_ADVANCE_COLLECTIONS_TABLE_PARAMS =
    'SET_ADVANCE_COLLECTIONS_TABLE_PARAMS';
export const SET_CUSTOMER_INVENTORY_TABLE_PARAMS =
    'SET_CUSTOMER_INVENTORY_TABLE_PARAMS';
export const SET_SETTLEMENTS_TABLE_PARAMS = 'SET_SETTLEMENTS_TABLE_PARAMS';
export const SET_SALES_DRAFT_TABLE_PARAMS = 'SET_SALES_DRAFT_TABLE_PARAMS';
export const SET_LOAD_SUMMARY_TABLE_PARAMS = 'SET_LOAD_SUMMARY_TABLE_PARAMS';
export const SET_UNLOAD_SUMMARY_TABLE_PARAMS =
    'SET_UNLOAD_SUMMARY_TABLE_PARAMS';
export const SET_INVENTORY_TABLE_PARAMS = 'SET_INVENTORY_TABLE_PARAMS';
export const SET_USERS_TABLE_PARAMS = 'SET_USERS_TABLE_PARAMS';
export const SET_EXPENSE_SUMMARY_TABLE_PARAMS =
    'SET_EXPENSE_SUMMARY_TABLE_PARAMS';
export const SET_SALESMAN_DAMAGE_SUMMARY_TABLE_PARAMS =
    'SET_SALESMAN_DAMAGE_SUMMARY_TABLE_PARAMS';
export const SET_SALESMAN_DAMAGE_SETTLEMENT_SUMMARY_TABLE_PARAMS =
    'SET_SALESMAN_DAMAGE_SETTLEMENT_SUMMARY_TABLE_PARAMS';
export const SET_DAY_TRACK_LOG_TABLE_PARAMS = 'SET_DAY_TRACK_LOG_TABLE_PARAMS';
export const SET_CREDIT_DEBIT_NOTE_TABLE_PARAMS =
    'SET_CREDIT_DEBIT_NOTE_TABLE_PARAMS';
export const SET_ROUTE_JOURNEY_LOG_TABLE_PARAMS =
    'SET_ROUTE_JOURNEY_LOG_TABLE_PARAMS';
